import React from "react";



const PersonalData = ({ signatureWidth, person, font }) => {



    let nameStyles = {
        fontSize: "29px",
        fontWeight: "bold",
        fontFamily: font
    };
    let positionStyles = {
        fontFamily: font,
        fontSize: "20px",
    };

    if (person.company.customCss) {
        if (person.company.customCss.name) {
            nameStyles = { ...nameStyles, ...person.company.customCss.name };
        }

        if (person.company.customCss.position) {
            positionStyles = { ...positionStyles, ...person.company.customCss.position };
        }
    }
    return <>
        <tr >
            <td
                colSpan={3}
                style={
                    nameStyles
                }
            >
                {person.firstName || "Imię"} {person.lastName || "Nazwisko"}
            </td>
        </tr>
        {person.positions.map((p, i) => <tr key={p.id} >
            <td
                colSpan={3}
                style={positionStyles}
            >
                {p.value || `Stanowisko#${i + 1}`}
            </td>
        </tr>

        )}
    </>
}

export default PersonalData;