import React from "react";


const SplitLine = ({ signatureWidth }) => {
    return <tr width={signatureWidth} height={"10px"}>
        <td
            width={signatureWidth}
            height={"10px"}
            colSpan={3}
            style={{ height: "10px" }}
        ></td>
    </tr>
}

export default SplitLine;