import { useEffect, useReducer, useState } from "react";

import EasyInput from "./EasyInput/EasyInput";
import EasyPositionInput from "./EasyInput/EasyPositionInput";
import EasySelect from "./EasyInput/EasySelect";
import EmailSignature from "./EmailSignature/EmailSignature";


function App() {
  const [person, setPerson] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      positions: [
        {
          value: "",
          id: Date.now()
        },
      ],
      company: null,
    }
  );
  const [companies, setCompanies] = useState({});

  const [isLoaded, setIsLoaded] = useState(false);
  const getCompanies = async () => {
    const response = await fetch("/env.json");
    const jsonResult = await response.json();

    setCompanies(jsonResult.companies);

    setIsLoaded(true);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const changePersonValue = (ev) => {
    setPerson({ [ev.target.id]: ev.target.value });
  };

  return (
    <div className="container-fluid p-4">
      <div className="row">
        <div className="col-xl-4 col-12 mt-2">
          <div className="p_box">
            <div className="p_box_header">
              Colian
            </div>
            <div className="p_box_content">
              <EasySelect
                id="company"
                label="Firma / Company"
                emptySelectLabel={"Wybierz spółkę"}
                value={person.company ? companies[person.company.name] : ""}
                onChange={(label) => setPerson({ company: companies[label] })}
                options={Object.keys(companies)}
              />
              <EasySelect
                id="address"
                label="Adres / Address"
                emptySelectLabel={person.company ? "Wybierz adres" : 'Wybierz spółkę'}
                value={person.address ? person.address : ""}
                onChange={(label) => setPerson({ address: label })}
                options={person.company ? person.company.address : []}
              />
              <EasyInput
                id="firstName"
                label="Imię / First Name"
                value={person.firstName}
                onChange={changePersonValue}
              />
              <EasyInput
                id="lastName"
                label="Nazwisko / Surname"
                value={person.lastName}
                onChange={changePersonValue}
              />
              <EasyInput
                id="email"
                label="Email"
                value={person.email}
                onChange={changePersonValue}
              />
              <EasyInput
                id="phone"
                label="Telefon / Telephone"
                value={person.phone}
                onChange={changePersonValue}
              />
              {person.positions.map((p, i) => {
                return (
                  <EasyPositionInput
                    key={p.id}
                    id={p.id}
                    label={`Stanowisko / Job title #${i + 1}`}
                    value={p.value}
                    onChange={(ev) =>
                      setPerson({
                        positions: person.positions.map((ip) => {
                          if (ip.id === p.id) {
                            ip.value = ev.target.value;
                          }
                          return ip;
                        }),
                      })
                    }
                    onRemove={() => {
                      setPerson({
                        positions: person.positions.filter((ip) => ip.id !== p.id),
                      });
                    }}
                  />
                );
              })}

              <button
                className="btn btn-dark mt-4"
                onClick={() =>
                  setPerson({
                    positions: [
                      ...person.positions,
                      { value: "", id: "position" + Date.now() },
                    ],
                  })
                }
              >
                Dodaj stanowisko / Add job title
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-12  mt-2">

          <div className="p_box">
            <div className="p_box_content">
              <EmailSignature person={person} />
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default App;
