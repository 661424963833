import React from 'react';
import ParsedElement from '../../Utils/CreateReplaceElement';
import CreateReplaceElement from '../../Utils/CreateReplaceElement';


const FooterItem = ({ text, customCss, signatureWidth, toReplace }) => {


    let style = {
        fontSize: "10px",
        paddingTop: "15px",
        textAlign: "justify",
    };
    if (customCss && typeof customCss === 'object') {
        style = { ...style, ...customCss };
    }

    return <tr width={signatureWidth}>

        <td
            width={signatureWidth}
            style={style}
        >
            <ParsedElement element={{ toReplace, text }} />
        </td></tr>
}
const Footer = ({ person, signatureWidth }) => {

    if (!person.company.footer || !Array.isArray(person.company.footer)) {
        return <></>;
    }
    return <table
        cellPadding={0}
        cellSpacing={0}
        border={0}
        width={signatureWidth}
        style={{
            fontSize: "12px",
            paddingTop: "15px",
            borderCollapse: "collapse",
            width: signatureWidth,
            color: "rgb(165 165 165)",
        }}
    >
        <tbody>
            {person.company.footer.map((el, index) => <FooterItem key={index} text={el.text} customCss={el.customCss} signatureWidth={signatureWidth} toReplace={el.toReplace} />)}
            {/* <tr width={signatureWidth}>

                <td
                    width={signatureWidth}
                    style={{
                        fontSize: "10px",
                        paddingTop: "15px",
                        textAlign: "justify",
                    }}
                >
                    Niniejsza wiadomość oraz jej załączniki zawiera ściśle poufne i
                    prawnie chronione informacje. Jeśli są Państwo jej omyłkowym
                    odbiorcą, prosimy o jej usunięcie i niezwłoczne poinformowanie
                    nadawcy. Kopiowanie, ujawnianie lub rozpowszechnianie materiału
                    zawartego w tym e-mailu jest zabronione. <br />
                    Administratorem Pani/ Pana danych jest Colian sp. z o.o. Więcej
                    informacji o zasadach przetwarzania danych dostępnych jest na
                    stronie:{" "}
                    <a
                        href="http://colian.pl/profil_korporacyjny/pl/obowiazek-informacyjny"
                        style={{ textDecoration: "none", color: "rgb(165 165 165)" }}
                    >
                        http://colian.pl/profil_korporacyjny/pl/obowiazek-informacyjny
                    </a>
                </td>
            </tr> */}
        </tbody>
    </table>
}

export default Footer;