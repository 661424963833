import React from "react";
import EasyInput from "./EasyInput";


const EasyPositionInput = ({
    label, value, onChange, id, onRemove
}) => {


    return <div className="row  align-items-end">
        <div className="col-8">
            <EasyInput id={id} label={label} value={value} onChange={onChange} />
        </div>
        <div className="col-4">
            <button className="btn btn-danger" onClick={onRemove}>Usuń / Delete</button>
        </div>
    </div>
}

export default EasyPositionInput;