import React, { useEffect, useRef } from "react";
import * as clipboard from "clipboard-polyfill";
import PersonalData from "./Elements/PersonalData";
import SplitLine from "./Elements/SplitLine";
import MidBox from "./Elements/MidBox";
import CompanyData from "./Elements/CompanyData";
import MediaData from "./Elements/MediaData";
import Banner from "./Elements/Banner";
import MockMsg from "../MockMsg";
import Footer from "./Elements/Footer";

const EmailSignature = ({ person }) => {
  const [copied, setCopied] = React.useState(false);

  const signatureRef = useRef();

  const mainColor = "#4E2B1E";
  useEffect(() => {
    if (copied) {
      setCopied(false);
    }
  }, [person]);

  if (!person.company) {
    return <div>Podgląd dostępny po wyborze spółki / Preview available after choosing the company</div>;
  }

  const color = person.company.color;
  const copySignature = async () => {
    const code = `
        <html lang="pl">
 
  <head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Document</title>
  </head>
  <body>

    <style>
    body {
     
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    #outlook a {
      padding: 0;
    }

    table td {
      border-collapse: collapse !important;
      font-family: "Trebuchet MS", Arial, sans-serif;
    }
  
  </style>
        ${signatureRef.current.innerHTML}
  </body>
</html>
`;
    const item = new clipboard.ClipboardItem({
      "text/html": new Blob([code], { type: "text/html" }),
    });

    await clipboard.write([item]);
    setCopied(true);
  };
  const signatureWidth = "630px";
  const font = "";

  return (
    <>
      <MockMsg />
      <div ref={signatureRef}>
        <table
          cellPadding={0}
          cellSpacing={0}
          border={0}
          width={signatureWidth}
          style={{
            width: `${signatureWidth}px`,
            borderCollapse: "collapse",
            color: mainColor,
            border: "none",
          }}
        >
          <tbody>
            <SplitLine signatureWidth={signatureWidth} />
            <PersonalData
              person={person}
              signatureWidth={signatureWidth}
              font={font}
            />
            <SplitLine signatureWidth={signatureWidth} />
            <MidBox
              person={person}
              color={color}
              signatureWidth={signatureWidth}
              textColor={mainColor}
            />
            <SplitLine signatureWidth={signatureWidth} />
            <CompanyData
              person={person}
              color={color}
              signatureWidth={signatureWidth}
              customCss={person.company.customCss && person.company.customCss.companyData ? person.company.customCss.companyData : {}}
            />
            <SplitLine signatureWidth={signatureWidth} />
            <MediaData
              person={person}
              color={color}
              signatureWidth={signatureWidth}
            />
            <SplitLine signatureWidth={signatureWidth} />

            <Banner person={person} />
          </tbody>
        </table>
        <Footer person={person} signatureWidth={signatureWidth} />
      </div>
      <br />

      <br />
      {copied ? (
        <button className="btn btn-success" onClick={copySignature} disabled>
          Stopka w schowku. Wklej do podpisu email. / Footer in the clipboard. Add to email signature.
        </button>
      ) : (
        <button className="btn btn-dark" onClick={copySignature}>
          Użyj / Use
        </button>
      )}
    </>
  );
};

export default EmailSignature;
