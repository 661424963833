import React from "react";


const MockMsg = () => {
    const [show, setShow] = React.useState(true);


    return <>
        <button className="btn btn-dark" onClick={() => setShow(!show)}>
            {show ? "Ukryj tekst / Hide text" : "Pokaż tekst / Show text"}
        </button>
        {show ? <p className="text-justify">
            <br />

            Dzień dobry,
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget elementum libero. Phasellus ullamcorper lacinia purus, ut facilisis nisi suscipit nec. Ut vulputate quam nulla, non feugiat felis convallis a. Fusce laoreet urna eget turpis porttitor volutpat. Suspendisse vestibulum mi ut massa interdum, at egestas turpis venenatis.
            <br />
            <br />
            Proin vulputate consectetur sapien, nec vulputate velit faucibus sed. Mauris blandit purus nisi.
        </p> : null}

    </>
}

export default MockMsg;