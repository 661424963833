import React from "react";
import Icon from "../Icon";
import MediaDataMoreInfoElement from "./MediaDataMoreInfoElement.js/MediaDataMoreInfoElement.js";



const MediaData = ({ person, color, signatureWidth }) => {
    return <tr>
        <td colSpan={3}>
            <table
                cellPadding={0}
                cellSpacing={0}
                border={0}
                width={signatureWidth}
                style={{
                    fontSize: "12px",
                    paddingTop: "15px",
                    borderCollapse: "collapse",
                    width: signatureWidth,
                }}
            >
                <tbody>
                    <tr style={{ verticalAlign: "middle" }}>
                        <td>
                            {person.company && person.company.logo ? (
                                <a href={`${person.company.mainUrl}`} >
                                    <img
                                        src={person.company.logo}
                                        height={40}
                                        style={{ width: "auto", height: "40px" }}
                                    />
                                </a>
                            ) : (
                                "logo"
                            )}
                        </td>
                        {person.company.media ? (
                            <>
                                <td style={{ width: "95px" }}>
                                    <span
                                        style={{
                                            color: person.company.mainColor,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Obserwuj nas:&nbsp;
                                    </span>
                                </td>
                                <td>
                                    {Object.keys(person.company.media).map((k) => {
                                        const i = person.company.media[k];
                                        return (
                                            < span key={i.url}>
                                                <a href={i.url} key={i.url}>
                                                    <Icon icon={i.img} />
                                                </a>
                                                &nbsp;
                                            </span>
                                        );
                                    })}
                                    &nbsp;
                                </td>
                            </>
                        ) : null}
                        {Array.isArray(person.company.moreInfo) && person.company.moreInfo.length ? (
                            person.company.moreInfo.map((mf, index) => <MediaDataMoreInfoElement key={index} element={mf} mainColor={person.company.mainColor} />)
                        ) : (
                            <>
                                <td width={200} style={{ width: "200px" }}>

                                </td>
                            </>
                        )}
                    </tr>
                </tbody>
            </table>
        </td>
    </tr >
}

export default MediaData;