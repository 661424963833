import React from "react";
import SignatureInfo from "../SignatureInfo";


const MidBox = ({ person, color, textColor }) => {
    const phonePrefix = person.company.phonePrefix || "+48";
    return <tr>
        <td width={"240px"} style={{ paddingRight: "10px" }}>
            <table
                width={"240px"}
                cellPadding={0}
                cellSpacing={0}
                border={0}
                style={{ borderCollapse: "collapse", fontSize: "12px", width: "240px" }}
            >
                <tbody>
                    <tr style={{ height: "40px" }} width={"240px"}>
                        <td>
                            <SignatureInfo
                                icon={person.company.mainIcons.phone}
                                color={textColor}
                                label={`${phonePrefix} ${person.phone.split("").map((c, i) => {
                                    const index = i + 1;
                                    if (index % 3 === 0) {
                                        return `${c} `;
                                    }
                                    return c;
                                }).join("")}`}
                                href={`tel:${phonePrefix}${person.phone.replace(/ /ig, "")}`}
                            />
                        </td>
                    </tr>
                    <tr style={{ height: "40px" }} width={"240px"}>
                        <td>
                            <SignatureInfo
                                icon={person.company.mainIcons.email}
                                color={textColor}
                                label={person.email || "email"}
                                href={`mailto:${person.email}`}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>

        <td width={"340px"}>
            <table
                width={"340px"}
                cellPadding={0}
                cellSpacing={0}
                border={0}
                style={{ borderCollapse: "collapse", width: "340px" }}
            >
                <tbody>
                    <tr style={{ height: "40px" }} width={"340px"}>
                        <td>
                            <SignatureInfo
                                icon={person.company.mainIcons.web}
                                color={textColor}
                                label={person.company.web}
                                href={`https://${person.company.web}`}
                            />
                        </td>
                    </tr>
                    <tr style={{ height: "40px" }} width={"340px"}>
                        <td>
                            <SignatureInfo
                                icon={person.company.mainIcons.location}
                                color={textColor}
                                label={`${person.address}` || "adres"}
                            // label={`${person.company.street}, ${person.company.city}`}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
}


export default MidBox;