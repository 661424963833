import React from "react";
import Icon from "../../Icon";


const MediaDataMoreInfoElement = ({ mainColor, element: { label, items, height } }) => {

    return <>
        <td style={{
            width: `${label.length * 7}px`,
            verticalAlign: "middle",
            textAlign: "center"
        }}>
            <span
                style={{
                    color: mainColor,
                    fontWeight: "bold",
                }}
            >
                &nbsp;{label}&nbsp;
            </span>

        </td>

        {items.map((i, index) => {
            const iHeight = height || 30;
            return (
                < td key={i.url + "_" + index} style={{
                    verticalAlign: "middle",
                    textAlign: "center"
                }}>

                    <a href={i.url} style={{
                        height: `${iHeight}px`
                    }} >
                        <Icon icon={i.img} autoWidth={true} height={iHeight} />
                    </a>

                </td>
            );
        })}


    </>
}

export default MediaDataMoreInfoElement;