import React from "react";
import Icon from "./Icon";

const ParsedLabel = ({ label }) => {
    const split = label.split("\n");
    return <>
        {split.map((l, index) => {
            if (index > 0) {
                return <span key={index}><br />{l}</span>
            }
            return <span key={index}>{l}</span>
        })}
    </>
}
const SignatureInfo = ({ icon, color, label, href }) => {

    let parsedLabel = label;
    if (label.includes("\n")) {
        parsedLabel = <ParsedLabel label={label} />;
    }

    return <table>
        <tbody border="0" cellPadding={0} cellSpacing={0} style={{ borderCollapse: "collapse", color: color }}>
            <tr>
                <td style={{ width: "45px!important" }}>
                    <Icon icon={icon} />
                </td>
                <td style={{ fontSize: "14px", paddingLeft: "10px" }}>
                    {
                        href ? <a style={{ textDecoration: "none", color: color }} href={href}>{parsedLabel}</a> : <span style={{ color: color }}>{parsedLabel}</span>
                    }

                </td>
            </tr>
        </tbody>
    </table >
}

export default SignatureInfo;