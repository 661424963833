import React from "react";


const EasySelect = ({
    id, label, value, onChange, options, emptySelectLabel
}) => {

    return <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <select className="form-control" id={id} name={id} value={value} onChange={ev => onChange(ev.target.value)}>
            <option value="">{emptySelectLabel}</option>
            {options.map(o => <option key={o} value={o}>{o}</option>)}
        </select>
    </div>
}

export default EasySelect