const CreateReplaceElement = ({ element }) => {
    const availableTags = ['href', 'style'];
    const attrs = {};
    if (element.attrs && Array.isArray(element.attrs)) {
        element.attrs.forEach(e => {
            if (availableTags.includes(e.name)) {
                attrs[e.name] = e.value;

            }
        });
    }

    if (element.customCss) {
        attrs.style = element.customCss;
    }


    switch (element.type) {
        case ("a"):
            return <a {...attrs}>{element.label}</a>
        default:
            <div>{element.label}</div>
    }
}

const ParsedElement = ({ element: { toReplace, text } }) => {

    return toReplace ? text.split("{{").map((i, index) => {
        const toReplaceDataIndex = toReplace ? toReplace.findIndex(t => `${t.key}` === i) : -1;
        if (toReplaceDataIndex > -1) {
            return <CreateReplaceElement key={index} element={toReplace[toReplaceDataIndex]} />
        }
        return <span key={index}>{i}</span>
    }) : text

}
export default ParsedElement;