import React from "react";
import ParsedElement from "../../Utils/CreateReplaceElement";


const CompanyData = ({ person, signatureWidth, color, customCss }) => {
    return <tr width={signatureWidth}>
        <td
            width={signatureWidth}
            colSpan={3}
            style={{
                verticalAlign: "middle",

                background: color,
                color: "#fff",
                fontSize: customCss && customCss.fontSize ? customCss.fontSize : "11px",
                height: "25px",
                width: signatureWidth,
            }}
        >
            <span> &nbsp; &nbsp;{person.company.name}</span>
            &nbsp;|&nbsp;

            {
                person.company.street ? <span style={{ wordWrap: "break-word" }}>
                    {person.company.street}&nbsp;|&nbsp;
                </span> : null
            }

            {
                person.company.city ? <span style={{ wordWrap: "break-word" }}>
                    {person.company.city}&nbsp;|&nbsp;
                </span> : null
            }

            {
                person.company.nip ? <span style={{ wordWrap: "break-word" }}>
                    NIP {person.company.nip}&nbsp;|&nbsp;
                </span> : null
            }
            {person.company.phone ? (
                <span style={{ wordWrap: "break-word" }}>
                    {person.company.phone}
                    &nbsp;|&nbsp;
                </span>
            ) : null}
            {Array.isArray(person.company.addBarInfo) && person.company.addBarInfo.length ?
                <>
                    {person.company.addBarInfo.map((e, index) => <span key={index} style={{ wordWrap: "break-word" }}><ParsedElement element={e} /></span>)}
                </>
                : null}

        </td>
    </tr>
}

export default CompanyData;