import React from "react";


const Icon = ({ icon, autoWidth, height = 30 }) => {
    const style = {};
    style.height = `${height}px`;

    style.width = autoWidth ? `auto` : `30px`;

    return <img src={icon} width={autoWidth ? "" : 30} height={height} style={style} />



}

export default Icon;