import React from "react";


const EasyInput = ({
    label, value, onChange, id
}) => {

    return <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <input className="form-control" name={id} id={id} type={"text"} value={value} onChange={onChange} />
    </div >
};


export default EasyInput;