import React from 'react';



const Banner = ({ person }) => {
    return person.company.banner ? (
        <tr>
            <td colSpan={3}>
                <a href={person.company.bannerUrl}>
                    <img src={person.company.banner} />
                </a>
            </td>
        </tr>
    ) : null
}

export default Banner;